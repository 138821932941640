









import { Component, Vue, Prop } from "vue-property-decorator";
import { UtilsPaciente } from "@/utils/utils-paciente";
import EstadisticasCitaModule from "@/store/modules/Dashboards/Empresarial/EstadisticasCita-module";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue")
  }
})
export default class FichaPacienteEstadisticas extends Vue {
  @Prop() source!:any;
  public async created() {}
  /*public get source() {
    return EstadisticasCitaModule.EstadisticasCita!
      .paciente_mas_citas_confirmadas;
  }*/
  public get ImagenPaciente() {
    return UtilsPaciente.getsexoFromObject(this.source);
  }
}
